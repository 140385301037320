.App {
}

.App-sidebar {
  padding: 0px;
}

.App-sidebar ul {
  list-style-type: none;
  padding: 0;
  display: inline-block;
  margin: 0;
}

.App-sidebar ul li {
  padding: 0;
  display: inline-block;
}

.App-sidebar ul li a {
  padding: 10px 20px;
  display: inline-block;
}

.App-view {
  padding: 0px;
}

.App-header {
}

.App-link {
  color: #61dafb;
}
